import { BaseComponent } from "../base/basecomponent.js";

export class CarbonAwareComponent extends BaseComponent {

    resourceSizeByType = {
        beacon: 0,
        css: 0,
        fetch: 0,
        img: 0,
        link: 0,
        other: 0,
        script: 0,
        xmlhttprequest: 0,
        total: 0
    };

    grams = '';

    constructor() {
        super();
        if (!carbonutils) {
            throw new Error('CarbonStatsComponent requires carbonutils');
        }

        this.stateStore = new carbonutils.StateStore();
        this.storageModel = new carbonutils.StorageModel();
        this.storageModelCarbonStats = new carbonutils.StorageModelCarbonStats();
        this.co2Calulator = new carbonutils.Co2Calulator();
    }

    async _connectedCallback() {

        this.attachShadow({ mode: 'open' });
        this.readConfig();
        this.attachPerformanceObserver();
        this.render();
        if (this.storageModel.hasStore()) {
            const data = this.storageModel.getStorageModel();
            if (data && data.co2) {
                this.grams = data.co2.grams;
                if (data.bytes) {
                    this.stateStore.setState('bytes', data.bytes);
                }
                this.render();
            }
        }
    }

    async processPerformanceEntries() {

        if (this.debug) {
            console.log('dataConsumption', this.resourceSizeByType)
        }
        this.bytes = this.resourceSizeByType.total;
        this.storageModelCarbonStats.setStorageModel({
            bytes: this.resourceSizeByType.total
        });
        const { co2: { grams } } = this.co2Calulator.getStatistics();
        this.grams = grams;
        this.render();

        this.stateStore.setState('bytes', this.resourceSizeByType.total);

        const co2Data = {
            grams,
            timestamp: new Date().getTime()
        };

        this.storageModel.setStorageModel({
            bytes: this.resourceSizeByType.total,
            co2: co2Data,
            data: this.resourceSizeByType
        });
    }

    readConfig() {
        this.debug = this.getAttribute('data-debug');
        this.config = this.getAttribute('data-config') ? JSON.parse(this.getAttribute('data-config')) : {};
        this.bgColor = this.config.bgColor || '#5cf65d';
        this.amountConfig = this.config.amount || {};
        this.beforeConfig = this.config.before || {};
        this.afterConfig = this.config.after || {};
        this.timetowait = this.getAttribute('data-timetowait') || 3000;
    }

    setupStlye() {
        this.shadowRoot.innerHTML += `
        <style>
            :host {
                ${this.bgColor ? `background-color: ${this.bgColor};` : ''}
                position: relative;
                display: flex;
                padding: 10px;
                width: 13rem;
                height: 13rem;
                border-radius: 50%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1.3rem;
                line-height: 1.2;
            }
            :host .co2-amount {
                margin: 0;
            }
            :host slot[name="before"] {
                font-size: ${this.beforeConfig.fontSize || 'initial'};
                color: ${this.beforeConfig.color || 'initial'};
            }
            :host .co2-amount {
                font-size: ${this.amountConfig.fontSize || 'initial'};
                color: ${this.amountConfig.color || 'initial'};
            }
            :host slot[name="after"] {
                font-size: ${this.afterConfig.fontSize || 'initial'};
            }
            :host ::slotted([slot="after"]) {
                color: ${this.afterConfig.color || 'initial'};
              }
            :host .hidden {
                display: none;
            }
            :host *:empty {
                visibility: hidden;
                margin: 0;
                padding: 0;
            }
        </style>
        `;
    }

    render() {

        this.shadowRoot.innerHTML = `
            <slot name="before">
                <p>This page emits</p>           
            </slot>
            <p class="co2-amount">${this.grams} g</p>
            ${!this.grams ? `
                <slot name="svg-loader">
                    <svg class="loader" width="25" height="25" viewBox="0 0 50 50">
                        <circle cx="25" cy="25" r="20" fill="none" stroke="#000" stroke-width="4" stroke-dasharray="90 150" stroke-linecap="round">
                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 25 25" to="360 25 25" dur="1s" repeatCount="indefinite"/>
                        </circle>
                    </svg>
                </slot>
            ` : ''}
            <slot name="after">
                <p>CO<sub>2</sub> per pageview</p>           
            </slot>
        `;
        this.setupStlye();
    }

    isExpired(timestamp) {
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        const oneWeekInMilliseconds = 7 * oneDayInMilliseconds;
        const currentTime = new Date().getTime();
        if (currentTime - timestamp > oneWeekInMilliseconds) {
            return true;
        }
        return false;
    }

    addResourceSize(entry) {
        let type = this.resourceSizeByType.hasOwnProperty(entry.initiatorType) ? entry.initiatorType : 'other';
        if (!isNaN(entry.decodedBodySize)) {
            let sizeInKB = entry.decodedBodySize;
            sizeInKB = parseFloat((sizeInKB).toFixed(2));

            this.resourceSizeByType[type] += sizeInKB;
            this.resourceSizeByType.total += sizeInKB;
            this.resourceSizeByType.total = parseFloat((this.resourceSizeByType.total).toFixed(2));
        }
    }

    attachPerformanceObserver() {
        for (const entry of performance.getEntriesByType('resource')) {
            this.addResourceSize(entry);
        }

        let po = new PerformanceObserver(list => {
            for (const entry of list.getEntries()) {
                this.addResourceSize(entry);
            }
        });

        po.observe({ entryTypes: ['resource'] });

        setTimeout(async () => {
            await this.processPerformanceEntries();
            po.disconnect();
        }, this.timetowait);
    }



}

customElements.define('carbon-aware-component', CarbonAwareComponent);