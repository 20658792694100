export class BaseComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        setTimeout(() => {
            this._isConnected = true;
            this._connectedCallback();
        });
    }
}